/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useState } from "react";
import { crudDeviceHandler } from "../lib/crudDevice";
import { createDevice } from "../lib/iotcrudapi";
import { s3Upload } from "../lib/s3Api";
import { CrudTextFields } from "./CrudTextFields";
import { DeviceViewModal } from "./DeviceViewModal";
import { DownloadButtons } from "./DownloadButtons";
import { NotifsSnackBar } from "./NotifsSnackBar";
import { downloadFile } from "../lib/downloadFile";

export function AddDeviceModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [customerName, setCustomerName] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [deviceDescription, setDeviceDescription] = useState<string>("");
  const [vpnIpAddress, setVpnIpAddress] = useState<string>("");
  const [vpnKey, setVpnKey] = useState<string>("");
  const [attachCert, setAttachCert] = useState<boolean>(true);
  const [inputVpnIpLoading, setInputVpnIpLoading] = useState<boolean>(false);
  const [getPvtkey, setGetPvtkey] = useState<string>("");
  const [getCertPem, setGetCertPem] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showCertDownload, setShowCertDownload] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  function s3UploadFunc(Body: string, Key: string) {
    s3Upload({ Body, Key })
      .then((result) => {
        if (result.success) {
          setSnackbarMessage("Private key successfully uploaded to S3");
          setSnackbarSeverity("success");
          setShowSnackbar(true);
          return;
        }
        setSnackbarMessage("Private key upload to S3 failed" + result.message);
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage(err.message);
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      });
  }

  function handleAdd() {
    setLoading(true);
    crudDeviceHandler({
      thingName: serialNumber,
      customer: customerName,
      vpnIp: vpnIpAddress,
      vpnKey,
      deviceDescription: deviceDescription
        ? deviceDescription.replace(/[^a-zA-Z0-9_.,@/:#-]/g, "@")
        : "",
      attachCert,
      apiMethod: createDevice,
    })
      .then((result) => {
        setSnackbarMessage("Device successfully created");
        setSnackbarSeverity("success");
        setShowSnackbar(true);
        if (result.cert) {
          downloadFile(result.cert.key, `${serialNumber}.priv.key`);
          downloadFile(
            result.cert.pem,
            `${serialNumber}-${result.cert.id}.pem.crt`
          );
          s3UploadFunc(
            result.cert.key,
            `${customerName}_${serialNumber}.priv.key`
          );
        }
        onClose();
      })
      .catch((err) => {
        console.log(err.message);
        setSnackbarMessage(err.message);
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <DeviceViewModal
        open={open}
        handleClose={onClose}
        modalType="Add Device"
        props={
          <>
            <CrudTextFields
              setSerialNumber={setSerialNumber}
              serialNumber={serialNumber}
              setCustomerName={setCustomerName}
              customerName={customerName}
              setDeviceDescription={setDeviceDescription}
              deviceDescription={deviceDescription}
              setVpnIpAddress={setVpnIpAddress}
              inputVpnIpLoading={inputVpnIpLoading}
              setInputVpnIpLoading={setInputVpnIpLoading}
              vpnKey={vpnKey}
              vpnIpAddress={vpnIpAddress}
              setVpnKey={setVpnKey}
              setCert={setAttachCert}
              method="create"
              cert={attachCert}
            />
            {showCertDownload && attachCert ? (
              <DownloadButtons
                getCertPem={getCertPem}
                getPvtkey={getPvtkey}
                serialNumber={serialNumber}
              />
            ) : null}
            <Box display="flex" justifyContent="space-between" marginTop={3}>
              {showCertDownload ? (
                <Button
                  variant="contained"
                  color="error"
                  sx={{ color: "white", fontWeight: "bold" }}
                  onClick={() => {
                    setSerialNumber("");
                    setCustomerName("");
                    setDeviceDescription("");
                    setVpnIpAddress("");
                    setVpnKey("");
                    setGetCertPem("");
                    setGetPvtkey("");
                    setAttachCert(true);
                    setShowCertDownload(false);
                  }}
                >
                  Clear
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ color: "white", fontWeight: "bold" }}
                  color="warning"
                  onClick={onClose}
                >
                  exit
                </Button>
              )}
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="success" />
                </Box>
              ) : null}
              {showCertDownload ? (
                <Button
                  variant="contained"
                  sx={{ color: "white", fontWeight: "bold" }}
                  color="warning"
                  onClick={onClose}
                >
                  exit
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  sx={{ color: "white", fontWeight: "bold" }}
                  onClick={handleAdd}
                >
                  Add Device
                </Button>
              )}
            </Box>
          </>
        }
      />
      <NotifsSnackBar
        styleProps={{
          bottom: "-6vh",
          left: snackbarSeverity === "success" ? "-2vw" : "62vw",
          position: "fixed",
        }}
        open={showSnackbar}
        setOpen={setShowSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
    </>
  );
}
