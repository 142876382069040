/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactElement, ReactNode } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Typography,
  Zoom,
} from "@mui/material";
import {
  Lock as LockIcon,
  CheckCircle as SuccessIcon,
} from "@mui/icons-material";

import {
  ValidationTextField,
  ValidationTextFieldProps,
  Validation,
} from "../components/ValidationTextField";
// hack for now for error type
export interface AuthError {
  code: string;
  message: string;
}

export const AuthForms = {
  Login: "login",
  SendConfirm: "sendconfirm",
  CodeChangePassword: "codechangepass",
  ChangePassword: "changepass",
  NewPassword: "newpass",
};

type AuthFieldProps = {
  startAdornmentIcon?: ReactElement;
  validation: Validation;
  onChangeValue: (name: string, value: string) => any;
} & ValidationTextFieldProps;

export function AuthField({
  startAdornmentIcon,
  validation,
  onChangeValue,
  ...props
}: AuthFieldProps) {
  return (
    <ValidationTextField
      margin="normal"
      fullWidth
      validation={validation}
      {...(startAdornmentIcon && {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start" sx={{ padding: 0 }}>
              {startAdornmentIcon}
            </InputAdornment>
          ),
          sx: {
            input: {
              boxShadow: "0 0 0 100px #484848 inset !important",
            },
          },
        },
      })}
      {...props}
      onChangeValue={(name, value) => {
        validation.setVisible(false);
        onChangeValue(name, value);
      }}
    />
  );
}

interface AuthCardProps {
  title: string;
  error?: string;
  success?: boolean;
  children: ReactNode;
}
export function AuthCard({ title, error, success, children }: AuthCardProps) {
  return (
    <Card
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#fff",
        backgroundColor: "#282828",
        borderRadius: "10px",
        width: "fit-content",
        padding: "25px",
      }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ background: "white" }}>
            <LockIcon sx={{ color: "#585656" }} />
          </Avatar>
        }
        title={title}
        titleTypographyProps={{ variant: "h5" }}
      />
      <CardContent>
        {success ? (
          <Box
            height="10em"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Zoom in>
              <SuccessIcon
                fontSize="inherit"
                color="success"
                sx={{ fontSize: "5em" }}
              />
            </Zoom>
          </Box>
        ) : (
          <>
            {error && (
              <Box bgcolor="background.paper" padding="8px">
                <Typography color="error.main">{error}</Typography>
              </Box>
            )}
            {children}
          </>
        )}
      </CardContent>
    </Card>
  );
}
