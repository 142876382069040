/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { useAuthContext } from "./components/AuthContext";

export interface Session {
  loaded: boolean;
  availableTenants: string[];
  availableSubsystems: string[];
}

const EMPTY_SESSION = {
  loaded: false,
  availableTenants: [],
  availableSubsystems: [],
};

const AuthContext = createContext<Session>(EMPTY_SESSION);
export function useSession() {
  return useContext(AuthContext);
}

export function SessionProvider({ children }: { children: ReactNode }) {
  const { user } = useAuthContext();
  const [session, setSession] = useState<Session>(EMPTY_SESSION);

  useEffect(() => {
    console.log("Session detected user change: " + Boolean(user));
    if (!user) {
      setSession(EMPTY_SESSION);
      return;
    }
  }, [user]);

  return (
    <AuthContext.Provider value={session}>{children}</AuthContext.Provider>
  );
}
