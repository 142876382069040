/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import { Alert, Snackbar } from "@mui/material";
import React from "react";

interface NotifsSnackBarProps {
  severity: "success" | "info" | "warning" | "error";
  message: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  styleProps: {};
}

export function NotifsSnackBar(props: NotifsSnackBarProps) {
  const { severity, message, open, setOpen, styleProps } = props;
  // console.log(props);
  return (
    <Snackbar
      open={open}
      autoHideDuration={900000}
      style={{ ...styleProps }}
      onClose={() => setOpen(false)}
    >
      <Alert onClose={() => setOpen(false)} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}
