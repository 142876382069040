/*******************************************************************************
 * (C) Copyright  2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

interface LinkProps extends BoxProps {
  children: ReactNode;
  onClick: () => any;
}
export function Link({ children, onClick, ...props }: LinkProps) {
  return (
    <Box {...props}>
      <a
        href="#"
        onClick={(evt) => {
          evt.preventDefault();
          onClick();
        }}
        style={{ color: "inherit", textDecoration: "inherit" }}
      >
        {children}
      </a>
    </Box>
  );
}
