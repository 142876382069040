/*******************************************************************************
 * (C) Copyright 2020, 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useState } from "react";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Person as UserIcon } from "@mui/icons-material";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useBrowserStorage } from "../lib/libBrowserStorage";
import { Link } from "../components/Link";
import { useValidation } from "../components/ValidationTextField";

import { AuthForms, AuthCard, AuthError, AuthField } from "./Common";

export default function SendConfirmationForm({
  handleAuth,
}: {
  handleAuth: (authProm: Promise<any>) => Promise<any>;
}) {
  const [storedUser, setStoredUser] = useBrowserStorage("username");
  const [email, setEmail] = useState<string>(storedUser || "");
  const [sending, setSending] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const validation = useValidation("SendConfirmationForm");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  function handleSendConfirmation(email: string) {
    if (validation.hasErrors) {
      validation.setVisible(true);
      return;
    }

    setSending(true);
    // TODO handle different result states
    handleAuth(Auth.forgotPassword(email))
      .then((result) => {
        console.log("Forgot password result");
        console.log(result);
      })
      .catch((err) => {
        const error = err as AuthError;
        setMessage(error.message);
      })
      .finally(() => {
        setSending(false);
      });
  }

  return (
    <AuthCard title="Reset Password" error={message}>
      <Typography align="center">
        A confirmation code will be sent to the following email address
      </Typography>
      <AuthField
        name="username"
        label="Email address"
        value={email}
        required
        emailAddress
        validation={validation}
        autoFocus
        disabled={sending}
        startAdornmentIcon={<UserIcon />}
        onChangeValue={(_, value) => {
          setMessage(undefined);
          setEmail(value);
        }}
        onEnter={() => {
          handleSendConfirmation(email);
        }}
      />
      <Stack alignItems="center">
        <Button
          fullWidth
          sx={{ mt: 3, mb: 2, color: "#fff", backgroundColor: "#424242" }}
          onClick={() => {
            handleSendConfirmation(email);
          }}
        >
          {sending ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Send Confirmation"
          )}
        </Button>
        <Stack direction="row">
          <Link
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Link>
          <Link
            paddingLeft="24px"
            onClick={() => {
              setSearchParams(
                (params) => {
                  params.set("method", AuthForms.CodeChangePassword);
                  return params;
                },
                { replace: true }
              );
            }}
          >
            I have a code
          </Link>
        </Stack>
      </Stack>
    </AuthCard>
  );
}
