/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import { Box, Divider, Menu, Typography } from "@mui/material";
import TableView from "./TableView";
import React from "react";
import logo from "../../public/logo.svg";
import ErrorBoundary from "../components/ErrorBoundary";
import UserButton from "../components/UserButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";

import {
  ExitToApp as LogoutIcon,
  Lock as PasswordIcon,
} from "@mui/icons-material";
import { MenuButton } from "../Page";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  return (
    <Box style={{ height: "100vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          width: "100%",
        }}
      >
        <Box sx={{ margin: "-15px 0 0 15px", position: "absolute" }}>
          <img
            alt="Westell"
            src={logo}
            height="182px"
            style={{
              paddingLeft: "8px",
              cursor: "pointer",
            }}
          />
        </Box>
        <Typography
          sx={{ margin: "auto", marginTop: "1.5%", marginBottom: ".5%" }}
          variant="h3"
        >
          Support Home
        </Typography>
        <Box paddingRight="4px" marginTop="1.6%" position="absolute" right="0">
          <UserButton
            menu={
              <Menu open={false}>
                <MenuButton
                  icon={<PasswordIcon />}
                  disabled={location.pathname === "/auth"}
                  text="Change Password"
                  onClick={() => {
                    navigate("/auth?method=changepass");
                  }}
                />
                <Divider />
                <MenuButton
                  icon={<LogoutIcon />}
                  text="Logout"
                  onClick={logout}
                />
              </Menu>
            }
          />
        </Box>
      </Box>
      <Box>
        <ErrorBoundary>
          <TableView />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};
export default HomePage;
