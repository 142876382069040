/*******************************************************************************
 * (C) Copyright 2022, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactNode } from "react";
import { Box, Stack, StackProps, SxProps } from "@mui/material";

interface BasePageProps extends Omit<StackProps, "title"> {
  titleLeft?: ReactNode;
  title?: ReactNode;
  titleRight?: ReactNode;
  headerSx?: SxProps;
}

export default function BasePage({
  titleLeft,
  title,
  titleRight,
  headerSx,
  children,
  ...props
}: BasePageProps) {
  return (
    <Stack height="100vh" {...props}>
      <Stack direction="row" sx={headerSx}>
        <Box flex="1">{titleLeft}</Box>
        {title && <Box>{title}</Box>}
        <Box flex="1" display="flex" justifyContent="flex-end">
          {titleRight}
        </Box>
      </Stack>
      {children}
    </Stack>
  );
}
