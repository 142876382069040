/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import {
  getSeverityFromString,
  getSeverityString,
  Severity,
} from "../lib/backend/common";

export interface NotificationConfig {
  email: string;
  sms: string;
  groups: { [group: string]: Severity };
}

export interface User {
  poolId: string;
  token: string;
  username: string;
  displayName: string;
  email: string;
  customer: string;
  notifications: {
    email: string;
    sms: string;
    groups: { [key: string]: Severity };
  };
  isTenant: boolean;
  displayRole: string;
  initials: string;
  userAdmin: boolean;
  attributes: { [key: string]: string | boolean };
}

export interface AuthInformation {
  user: User | null;
  changePassword: (previousPassword: string, password: string) => Promise<any>;
  updateUserAttributes: (config: NotificationConfig) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthInformation>({
  user: null,
  changePassword: async () => {},
  updateUserAttributes: async () => {},
  logout: () => {},
});
export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [loaded, setLoaded] = useState(false);
  const cognitoUser = useRef<any>();

  async function getUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then((awsUser) => {
          cognitoUser.current = awsUser;
          const displayName = awsUser.attributes["name"];
          const isCustomer = awsUser.attributes["custom:is_admin"];
          const isTenant = isCustomer?.toUpperCase() !== "YES";

          const groups: { [group: string]: Severity } = {};
          const groupsVal = awsUser.attributes["custom:roles"] as string;
          if (groupsVal) {
            groupsVal.split(" ").forEach((item) => {
              const tokens = item.split(":");
              tokens[1].toLocaleLowerCase() === "info"
                ? (tokens[1] = "informational")
                : tokens[1];

              if (tokens.length === 2) {
                groups[tokens[0]] = getSeverityFromString(tokens[1]);
              }
            });
          }
          const notifications: NotificationConfig = {
            email: awsUser.attributes["custom:notification_email"] || "",
            sms: awsUser.attributes["custom:notification_number"] || "",
            groups,
          };
          resolve({
            poolId: awsUser.pool.userPoolId,
            token: awsUser.signInUserSession.accessToken.jwtToken,
            username: awsUser.username,
            displayName,
            email: awsUser.attributes.email,
            customer: awsUser.attributes["custom:customer"],
            isTenant,
            notifications,
            displayRole: isTenant ? "Tenant" : "Administrator",
            initials: displayName
              .split(" ")
              .map((token: string) => token[0])
              .join(""),
            userAdmin:
              (awsUser.attributes[
                "custom:user_admin"
              ] as string)?.toUpperCase() === "YES",
            attributes: awsUser.attributes,
          });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async function refreshUser() {
    getUser()
      .then((user) => {
        setUserInfo(user);
      })
      .catch((error) => {
        setUserInfo(null);
      })
      .finally(() => {
        // we've at least attempted to load
        setLoaded(true);
      });
  }

  function handleAuth({ payload }: { payload: any }) {
    switch (payload.event) {
      case "signIn":
      case "signUp":
      case "signIn_failure":
        refreshUser();
        break;
      case "signOut":
        setUserInfo(null);
        break;
    }
  }

  function handleLogout() {
    Auth.signOut();
  }

  async function handleChangePassword(
    previousPassword: string,
    password: string
  ) {
    if (!userInfo || !cognitoUser.current) {
      console.log("No user to update");
      return;
    }

    return Auth.changePassword(cognitoUser.current, previousPassword, password);
  }

  async function updateUserAttributes({
    email,
    sms,
    groups,
  }: NotificationConfig): Promise<void> {
    if (!userInfo || !cognitoUser.current) {
      console.error("No user to update");
      return;
    }
    const attributes = {
      "custom:notification_email": email || "",
      "custom:notification_number": sms || "",
      "custom:notification_methods":
        email && sms ? "email sms" : email ? "email" : sms ? "sms" : "",
      "custom:roles": Object.entries(groups)
        .map(([key, value]) => `${key}:${getSeverityString(value)}`)
        .join(" "),
    };

    return Auth.updateUserAttributes(cognitoUser.current, attributes)
      .then(() => {
        setUserInfo(
          (prevInfo) =>
            prevInfo && {
              ...prevInfo,
              notifications: {
                email,
                sms,
                groups,
              },
              attributes: {
                ...userInfo.attributes,
                ...attributes,
              },
            }
        );
      })
      .catch((error) => {
        alert(error);
      });
  }

  useEffect(() => {
    const hubListenerCancelToken = Hub.listen("auth", handleAuth);
    refreshUser();
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: userInfo,
        changePassword: handleChangePassword,
        updateUserAttributes,
        logout: handleLogout,
      }}
    >
      {loaded && children}
    </AuthContext.Provider>
  );
}
