/*******************************************************************************
 * (C) Copyright 2020-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactElement, useState } from "react";
import {
  Avatar,
  Box,
  ButtonBase,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import { useAuthContext } from "./AuthContext";

/*******************************************************************************
 * UserButton
 */
export default function UserButton({ menu }: { menu: ReactElement }) {
  const { user } = useAuthContext();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | undefined>(
    undefined
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setMenuAnchor(undefined);
        }}
      >
        <ButtonBase
          focusRipple
          sx={{
            display: "flex",
            alignSelf: "stretch",
            paddingLeft: 1,
            paddingRight: 1,
          }}
          onClick={(evt) => {
            setMenuAnchor(evt.currentTarget);
          }}
        >
          <Box
            sx={{
              color: "#FFFFFF",
              marginRight: 1,
            }}
          >
            <Typography variant="body2" align="right">
              {user.displayName}
            </Typography>
            <Typography
              variant="caption"
              align="right"
              sx={{ fontWeight: 300, color: "#FFFFFF" }}
            >
              {user.displayRole}
            </Typography>
          </Box>
          <Avatar sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
            {user.initials}
          </Avatar>
        </ButtonBase>
      </ClickAwayListener>
      {React.cloneElement(menu, {
        anchorEl: menuAnchor,
        open: Boolean(menuAnchor),
        onClose: () => setMenuAnchor(undefined),
      })}
    </>
  );
}
