/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */

export const CONNECTED_STATE = "ConnectedState";
export const DISCONNECTED = "disconnected";

export const TENANT_TAG_PREFIX = "tenant-";
export const SUBSYSTEM_TAG_PREFIX = "subsystem-";

// matches vantage-ux-api
export enum Severity {
  unknown = -1,
  normal = 0,
  informational = 10,
  harmless = 20,
  warning = 30,
  minor = 40,
  major = 50,
  critical = 60,
}

export interface OrderBy {
  field: string;
  descending: boolean;
  limit?: number;
  offset?: number;
}

/*******************************************************************************
 * Parse a string value for boolean-ness
 *
 * @returns the value
 */
export function parseBool(value?: string | null): boolean {
  value = value && value.toLowerCase();
  return value == "yes" || value == "true";
}

/*******************************************************************************
 * Get the severity enum value from the integer number
 *
 * @param severity The integer value
 *
 * @return the enum value
 */
export function getSeverityFromValue(severity: number): Severity {
  if (severity === 0) {
    return Severity.normal;
  } else if (severity === 10) {
    return Severity.informational;
  } else if (severity === 20) {
    return Severity.harmless;
  } else if (severity === 30) {
    return Severity.warning;
  } else if (severity === 40) {
    return Severity.minor;
  } else if (severity === 50) {
    return Severity.major;
  } else if (severity === 60) {
    return Severity.critical;
  } else {
    return Severity.unknown;
  }
}

/*******************************************************************************
 * Return the name for a Severity enum.
 *
 * @param severity the Severity
 * @returns a name of the severity
 */
export function getSeverityString(severity?: Severity): string {
  if (severity === Severity.normal) {
    return "Normal";
  } else if (severity === Severity.informational) {
    return "Info";
  } else if (severity === Severity.harmless) {
    return "Harmless";
  } else if (severity === Severity.warning) {
    return "Warning";
  } else if (severity === Severity.minor) {
    return "Minor";
  } else if (severity === Severity.major) {
    return "Major";
  } else if (severity === Severity.critical) {
    return "Critical";
  } else {
    return "Unknown";
  }
}

/*******************************************************************************
 * Get the severity value from a string
 *
 * @param value The severity string
 *
 * @return @return Severity
 */
export function getSeverityFromString(value: string): Severity {
  switch (value.toLowerCase()) {
    case "normal":
      return Severity.normal;
    case "informational":
      return Severity.informational;
    case "warning":
      return Severity.warning;
    case "minor":
      return Severity.minor;
    case "major":
      return Severity.major;
    case "critical":
      return Severity.critical;
    default:
      return Severity.unknown;
  }
}
