/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

/*******************************************************************************
 * Function takes in data and a file name and downloads the file
 */

export function downloadFile(data: any, fileName: string) {
  const element = document.createElement("a");
  const file = new Blob([data], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}
