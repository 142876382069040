/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import API from "./api";

/*******************************************************************************
 * listDevices calls getThingsAndConnections and returns a list of devices from IOT core
 */

export function listDevices(): Promise<[]> {
  return API.get<[]>("/getThingsAndConnections", "");
}

/*******************************************************************************
 * updateThing takes a thingName(serialNumber), customer, deviceDescription, vpnIp, vpnKey,
 * and updates the device
 * @param thingName // serialNumber
 * @param customer
 * @param deviceDescription
 * @param vpnIp
 * @param vpnKey
 * @param attachCert
 */

export function updateThing(params: {
  thingName: string;
  customer: string;
  deviceDescription: string;
  vpnIp: string;
  vpnKey: string;
}): Promise<boolean> {
  const apiName = "/updateThing";
  const path = "PUT";
  const myInit = {
    body: JSON.stringify(params),
  };
  // console.log("params are ", myInit);
  return API.put(apiName, path, myInit);
}

/*******************************************************************************
 * createDevice takes a thingName(serialNumber), customer, deviceDescription, vpnIp, vpnKey,
 * and attachCert (a boolean indacting wether or not to create and attach cert) and creates a new device
 * @param thingName // serialNumber
 * @param customer
 * @param deviceDescription
 * @param vpnIp
 * @param vpnKey
 * @param attachCert
 */

export function createDevice(params: {
  thingName: string;
  attachCert: boolean;
  customer: string;
  deviceDescription: string;
  vpnIp: string;
  vpnKey: string;
}): Promise<{ success: boolean; data: any }> {
  const apiName = "/createThing";
  const path = "PUT";
  const myInit = {
    body: JSON.stringify(params),
    headers: {},
  };
  // console.log("params are ", myInit);
  return API.put(apiName, path, myInit);
}

/*******************************************************************************
 * getDevicePrivateKey takes a thingName(serialNumber) and returns the certificate
 * @param thingName // serialNumber
 */

export function getDeviceCertificate(thingName: string): Promise<boolean> {
  const params = {
    thingName,
  };
  const apiName = "/getThingCertificate";
  const path = "GET";
  const myInit = {
    body: JSON.stringify(params),
    headers: {},
  };
  // console.log("params are ", myInit);
  return API.get(apiName, path, myInit);
}

/*******************************************************************************
 * deleteDevice takes a thingName(serialNumber) and deletes the device from AWS IoT
 * @param thingName // serialNumber
 */

export function deleteDevice(
  thingName: string
): Promise<{ success: boolean; data: any; errorMessage: string }> {
  const params = {
    thingName,
  };
  const apiName = "/deleteThing";
  const path = "PUT";
  const myInit = {
    body: JSON.stringify(params),
    headers: {},
  };
  // console.log("params are ", myInit);

  return API.put(apiName, path, myInit);
}
