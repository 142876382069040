/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import { WifiTwoTone, WifiOffTwoTone } from "@mui/icons-material";
import { GridValueGetterParams } from "@mui/x-data-grid";
import React from "react";

/*******************************************************************************
 * Function takes in a boolean value and returns a wifi icon based on the value.
 */
export function ConnectionIcon(params: GridValueGetterParams | any) {
  if (params.value === true) {
    return <WifiTwoTone style={{ color: "#19f500" }} />;
  }
  return <WifiOffTwoTone style={{ color: "#e30000" }} />;
}
