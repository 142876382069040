/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Box,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { deleteDevice } from "../lib/iotcrudapi";
import { wireguardCUDPeer } from "../lib/wireguardApi";
import { s3Remove } from "../lib/s3Api";

export interface SelectedRows {
  serialNumber: string;
  vpnIp: string;
  vpnKey: string;
  customerName?: string;
}

const handleDelete = ({
  setShow,
  setSnackbarSeverity,
  setSnackbarMessage,
  setLoadDelete,
  setOpenDelete,
  selectedRows,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<"success" | "error" | "warning" | "info">
  >;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setLoadDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: {
    serialNumber: string;
    vpnIp: string;
    vpnKey: string;
    customerName?: string;
  }[];
}) => {
  setLoadDelete(true);
  for (const devices of selectedRows) {
    if (devices.vpnIp && devices.vpnKey) {
      wireguardCUDPeer({
        vpnIp: devices.vpnIp,
        vpnKey: devices.vpnKey,
        method: "removepeer",
      })
        .then((res) => {
          // console.log(res);
          setShow(true);
          setSnackbarSeverity("success");
          setSnackbarMessage("Removed key and ip from wireguard");

          if (devices.serialNumber === "N/A") {
            return;
          }

          deleteDevice(devices.serialNumber)
            .then((res) => {
              setShow(true);
              setSnackbarSeverity("success");
              setSnackbarMessage("Device Deleted from AWS");
              setLoadDelete(false);
              setOpenDelete(false);
            })
            .catch((err) => {
              setShow(true);
              setSnackbarSeverity("error");
              setSnackbarMessage("Error Deleting Device " + err);
              console.log(err);
              console.log(err.response);
            });
        })
        .catch((err) => {
          setShow(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Error Deleting Device " + err);
          console.log(err);
          console.log(err.response);
        });
    }

    deleteDevice(devices.serialNumber)
      .then((res) => {
        console.log(res);
        setShow(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Device Deleted from AWS");
        setLoadDelete(false);
        setOpenDelete(false);
      })
      .catch((err) => {
        setShow(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error Deleting Device" + err);
        setLoadDelete(false);
        console.log(err);
        console.log(err.response);
      });
    // TODO: Check if the device key is in the s3 bucket before deleting
    s3Remove({
      Key: devices.customerName + "_" + devices.serialNumber + ".priv.key",
    })
      .then((res) => {
        if (!res.success) {
          setShow(true);
          setSnackbarSeverity("error");
          setSnackbarMessage("Error Deleting Private KEY: " + res.message);
          setLoadDelete(false);
          console.log(res);
          console.log(res.message);
        }
        console.log(res);
        setShow(true);
        setSnackbarSeverity("success");
        setSnackbarMessage("Private Key Deleted from S3");
        setLoadDelete(false);
        setOpenDelete(false);
      })
      .catch((err) => {
        setShow(true);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error Deleting Private KEY: " + err);
        setLoadDelete(false);
        console.log(err);
        console.log(err.response);
      });
  }
};

export function ConfirmDeleteDialog({
  openDelete,
  setOpenDelete,
  selectedRows,
  loadDelete,
  setLoadDelete,
  setShow,
  setSnackbarSeverity,
  setSnackbarMessage,
}: {
  openDelete: boolean;
  setOpenDelete: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: {
    serialNumber: string;
    vpnIp: string;
    vpnKey: string;
  }[];
  loadDelete: boolean;
  setLoadDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<"success" | "error" | "warning" | "info">
  >;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [match, setMatch] = useState<string>("");
  return (
    <div>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle>Confirm Deletion of ..</DialogTitle>
        {selectedRows.map((row) => {
          return (
            <DialogContent
              key={row.serialNumber}
              sx={{ padding: "6px", color: "#22f900", margin: "15px" }}
            >
              <DialogContentText>
                SN :{" "}
                <span style={{ color: "#22f900" }}>{row.serialNumber}</span>
              </DialogContentText>
              <DialogContentText>
                IP : <span style={{ color: "#22f900" }}>{row.vpnIp} </span>
              </DialogContentText>
              <DialogContentText>
                KEY : <span style={{ color: "#22f900" }}>{row.vpnKey} </span>
              </DialogContentText>
            </DialogContent>
          );
        })}
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected devices? To confirm,
            please type the following in the field below: "Confirm Delete"
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="text"
            fullWidth
            required
            onChange={(e) => setMatch(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          {loadDelete ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="success" />
            </Box>
          ) : null}
          <Button
            sx={{ color: "orange", fontWeight: "bold" }}
            onClick={() => setOpenDelete(false)}
          >
            Cancel
          </Button>
          <Button
            sx={{ color: "red", fontWeight: "bold" }}
            disabled={match !== "Confirm Delete"}
            onClick={() =>
              handleDelete({
                setShow,
                setSnackbarSeverity,
                setSnackbarMessage,
                setLoadDelete,
                setOpenDelete,
                selectedRows,
              })
            }
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
