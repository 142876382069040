/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import API from "./api";

/*******************************************************************************
 * s3Upload - upload a cert private key to S3
 * @param params{} - the params to pass to the API
 *  params = {
 *   Body: string,
 *  Key: string
 * }
 * @returns - a promise that resolves to a s3Response
 */
export function s3Upload(params: {
  Body: string;
  Key: string;
}): Promise<{ success: boolean; data: any; message: string }> {
  const apiName = "/s3upload";
  const path = "PUT";
  const myInit = {
    body: JSON.stringify(params),
  };
  return API.put(apiName, path, myInit);
}

/*******************************************************************************
 * s3Download - download a cert private key from S3
 */
export function s3Download(params: {
  Key: string;
}): Promise<{
  success: boolean;
  data: any;
  message: string;
}> {
  const apiName = "/s3download";
  const path = "GET";
  const myInit = {
    body: JSON.stringify(params),
  };
  return API.get(apiName, path, myInit);
}

/*******************************************************************************
 * s3Remove - remove a cert private key from S3
 */

export function s3Remove(params: {
  Key: string;
}): Promise<{
  message: string;
  success: boolean;
  data: any;
}> {
  const apiName = "/s3remove";
  const path = "POST";
  const myInit = {
    body: JSON.stringify(params),
  };
  return API.post(apiName, path, myInit);
}
