/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import Auth from "@aws-amplify/auth";
import { API } from "@aws-amplify/api";
import { Amplify } from "@aws-amplify/core";

import { QueryResponse } from "./types/common";

import { constants } from "../App";
import { Session } from "../SessionContext";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

export const API_NAME = "InternalAPI";

// Create wrapper types in case we ever change implementation
export interface QueryOptions<TData> extends UseQueryOptions<TData> {}
export declare type QueryResult<TData> = UseQueryResult<TData>;

export function configure() {
  //console.log("Configuring API");
  //console.log(constants);
  Amplify.configure({
    Auth: {
      region: constants.UX_STACK_REGION,
      userPoolId: constants.UX_COGNITO_USER_POOL,
      userPoolWebClientId: constants.UX_COGNITO_WEB_CLIENT,
    },
    API: {
      endpoints: [
        {
          name: API_NAME,
          endpoint: "/api",
          custom_header: async () => {
            return {
              Authorization: (await Auth.currentSession())
                .getIdToken()
                .getJwtToken(),
            };
          },
        },
      ],
    },
  });
}
// configure();
/*******************************************************************************
 * AWS API get wrapper
 *
 * @returns The templated result type
 */
export function get<TData>(
  uri: string,
  method: string,
  queryArgs?: object
): Promise<TData> {
  return new Promise((resolve, reject) => {
    console.log("api get: " + uri + " - " + method);
    API.get(API_NAME, uri, {
      queryStringParameters: {
        method,
        ...queryArgs,
      },
    })
      .then((result: QueryResponse) => {
        // if (!result.success) {
        //   reject(result.message || "Unknown error");
        // }
        resolve(result.data as TData);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*******************************************************************************
 * useQuery wrapper around get call
 *
 * @param queryKey
 * @param uri
 * @param method
 * @param queryArgs
 * @param options
 *
 * @return TData
 */
export function getQuery<TData>(
  queryKey: QueryKey,
  uri: string,
  method: string,
  queryArgs?: object,
  options?: QueryOptions<TData>
): QueryResult<TData> {
  return useQuery<TData>(
    queryKey,
    () => {
      return get<TData>(uri, method, queryArgs);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
}

/*******************************************************************************
 * post
 */
export function post<TResult>(
  uri: string,
  method: string,
  queryArgs?: object,
  body?: any
): Promise<TResult> {
  console.log("api post: " + uri + " - " + method);
  return API.post(API_NAME, uri, {
    queryStringParameters: {
      method,
      ...queryArgs,
    },
    body,
  });
}

export function put(
  uri: string,
  method: string,
  queryArgs?: object,
  body?: any
): Promise<any> {
  return API.put(API_NAME, uri, {
    queryStringParameters: {
      method,
      ...queryArgs,
    },
    body,
  });
}

function del(
  uri: string,
  method: string,
  queryArgs?: object,
  body?: any
): Promise<any> {
  const myInit = {
    body,
  };
  return API.del(API_NAME, uri, myInit);
}

export default {
  get,
  getQuery,
  post,
  put,
  del
};
