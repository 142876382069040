/*******************************************************************************
 * (C) Copyright 2020, 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonBase,
  CircularProgress,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { Lock as LockIcon, Person as UserIcon } from "@mui/icons-material";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  useBrowserStorage,
  useBrowserStorageBool,
} from "../lib/libBrowserStorage";

import { Link } from "../components/Link";
import { useAuthContext } from "../components/AuthContext";
import { useValidation } from "../components/ValidationTextField";

import { AuthCard, AuthError, AuthField, AuthForms } from "./Common";

export default function LoginForm({
  handleAuth,
}: {
  handleAuth: (authProm: Promise<any>) => Promise<any>;
}) {
  const { user } = useAuthContext();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [rememberUser, setRememberUser] = useBrowserStorageBool("rememberUser");
  const [storedUser, setStoredUser] = useBrowserStorage("username");
  const [message, setMessage] = useState<string>();
  const validation = useValidation("LoginForm");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  function handleLogin(email: string, password: string) {
    if (validation.hasErrors) {
      validation.setVisible(true);
      return;
    }
    setSending(true);
    // TODO handle different result states

    handleAuth(Auth.signIn(email, password))
      .then(() => {
        setSuccess(true);
        if (rememberUser) {
          setStoredUser(email);
        }
      })
      .catch((err) => {
        const error = err as AuthError;
        switch (error.code) {
          case "UserNotFoundException":
          case "NotAuthorizedException": {
            setMessage("The username or password you entered is incorrect");
            break;
          }
          // MFA?
          case "UserNotConfirmedException": {
            setMessage("MFA timeout");
            break;
          }
          case "InvalidPasswordException": {
            setMessage(err.message);
            break;
          }
          // navigate to change password?
          case "PasswordResetRequiredException": {
            break;
          }
          default:
            setMessage("Unknown error");
            console.error(error);
        }
        setSending(false);
      });
  }

  useEffect(() => {
    const usernameParam = searchParams.get("username");
    if (usernameParam) {
      setEmail(usernameParam);
    } else if (rememberUser && storedUser) {
      setEmail(storedUser);
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (user && !sending) {
      navigate("/");
    }
  }, [user]);

  if (!loaded) {
    return null;
  }

  return (
    <AuthCard title="Login" error={message} success={success}>
      <AuthField
        name="email"
        label="Email address"
        value={email}
        required
        emailAddress
        validation={validation}
        autoFocus={!Boolean(email)}
        disabled={sending}
        startAdornmentIcon={<UserIcon />}
        onChangeValue={(_, value) => {
          setMessage(undefined);
          setEmail(value);
        }}
        onEnter={() => {
          handleLogin(email, password);
        }}
      />
      <AuthField
        name="password"
        label="Password"
        type="password"
        value={password}
        required
        minLength={8}
        validation={validation}
        autoFocus={Boolean(email)}
        disabled={sending}
        startAdornmentIcon={<LockIcon />}
        onChangeValue={(_, value) => {
          setMessage(undefined);
          setPassword(value);
        }}
        onEnter={() => {
          handleLogin(email, password);
        }}
      />
      <ButtonBase
        sx={{ width: "100%", paddingLeft: "8px" }}
        onClick={() => {
          setRememberUser((prev) => {
            if (prev) {
              setStoredUser(null);
            }
            return !prev;
          });
        }}
      >
        <Typography flex="1" align="left">
          Remember email address
        </Typography>
        <Switch
          color={rememberUser ? "success" : "error"}
          checked={rememberUser}
        />
      </ButtonBase>
      <Stack alignItems="center">
        <Button
          fullWidth
          sx={{ mt: 3, mb: 2, color: "#fff", backgroundColor: "#424242" }}
          onClick={() => {
            handleLogin(email, password);
          }}
        >
          {sending ? (
            <CircularProgress size={24} sx={{ color: "green" }} />
          ) : (
            "Login"
          )}
        </Button>
        <Link
          onClick={() => {
            setSearchParams((params) => {
              params.set("method", AuthForms.SendConfirm);
              return params;
            });
          }}
        >
          Forgot Password
        </Link>
      </Stack>
    </AuthCard>
  );
}
