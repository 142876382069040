import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Content from "./Content";
import { AuthProvider } from "./components/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0",
          paddingLeft: "4.5px",
          paddingRight: "5px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            color: "#fff",
          },
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              borderColor: "#fff",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#fff",
            },
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          background:
            "radial-gradient(circle, rgba(135,134,134,1) 21%, rgba(52,55,60,1) 70%, rgba(52,55,60,1) 71%)",
          backgroundSize: "cover",
        },
        "*": {
          margin: "0",
          padding: "0",
          boxSizing: "border-box",
        },
      }),
    },
  },

  palette: {
    mode: "dark",
    background: {
      default: "#b1b1b1",
      paper: "#424242",
    },
    primary: {
      main: "#424242",
    },
    secondary: {
      main: "#fff",
    },
  },
});

const stage = process.env.STAGE!;
export const constants: { [name: string]: string } = {
  UX_NAME: "ux_support",
  UX_DISPLAY_NAME: "Vantage Support",
  UX_STACK_REGION: process.env.UX_STACK_REGION!,
  UX_COGNITO_USER_POOL: process.env.UX_COGNITO_USER_POOL!,
  UX_COGNITO_WEB_CLIENT: process.env.UX_COGNITO_WEB_CLIENT!,
  STAGE: stage,
  WIREGUARD_SUBNET: stage === "prod" ? "1" : "0",
};

function validate() {
  var valid = true;
  for (var key of Object.keys(constants)) {
    if (constants[key] === undefined) {
      console.log("Must define " + key + " in environment!");
      valid = false;
    } else {
      //console.log(key + ":" + constants[key]);
    }
  }
  return valid;
}
const isEnvValid = validate();
const queryClient = new QueryClient();

export default function App() {
  if (!isEnvValid) {
    return <div>Invalid environment</div>;
  }
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Content />
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
