/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import {
  IconButton,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { GridRenderCellParams, GridTreeNodeWithRender } from "@mui/x-data-grid";
import React, { useState } from "react";
import { crudDeviceHandler } from "../lib/crudDevice";
import { getCertificate } from "../lib/downloadCert";
import { s3Download } from "../lib/s3Api";
import { updateThing } from "../lib/iotcrudapi";
import { CrudTextFields } from "./CrudTextFields";
import { DeviceViewModal } from "./DeviceViewModal";
import {
  Close as CloseIcon,
  Edit as EditIcon,
  GetApp as DownloadIcon,
  Visibility as ViewIcon,
} from "@mui/icons-material";
import { NotifsSnackBar } from "./NotifsSnackBar";
import { downloadFile } from "../lib/downloadFile";

/*******************************************************************************
 * Modal component for viewing device details and editing/updating on icon click
 */
export function DeviceModals({
  row,
}: {
  row: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [editDetails, setEditDetails] = useState<boolean>(false);
  const [serialNumber, setSerialNumber] = useState<string>(
    row.row.serialNumber
  );
  const [deviceDescription, setDeviceDescription] = useState<string>(
    row.row.description
  );
  const [customer, setCustomer] = useState<string>(row.row.customer);
  const [vpnIp, setVpnIp] = useState<string>(row.row.vpnIp);
  const [vpnKey, setVpnKey] = useState<string>(row.row.vpnKey);
  const [cert, setCert] = useState<boolean>(false);
  const [inputVpnIpLoading, setInputVpnIpLoading] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  // console.log(row.row.vpnIp, row.row.vpnKey);
  function handleUpdate() {
    setLoading(true);

    const keyAndIpNotUpdated =
      vpnIp === row.row.vpnIp && vpnKey === row.row.vpnKey;

    crudDeviceHandler({
      thingName: serialNumber,
      customer,
      vpnIp: keyAndIpNotUpdated ? "" : vpnIp,
      vpnKey: keyAndIpNotUpdated ? "" : vpnKey,
      deviceDescription: deviceDescription
        ? deviceDescription.replace(/[^a-zA-Z0-9_.,@/:#-]/g, "@")
        : "",
      attachCert: cert,
      apiMethod: updateThing,
    })
      .then((result) => {
        if (result.cert) {
          downloadFile(result.cert.key, `${serialNumber}.priv.key`);
          downloadFile(
            result.cert.pem,
            `${serialNumber}-${result.cert.id}.pem.crt`
          );
          //setShowCertDownload(true);
        }
        setSnackbarMessage("Device updated!");
        setSnackbarSeverity("success");
        setShowSnackbar(true);
        setEditDetails(false);
      })
      .catch((err) => {
        setSnackbarMessage(err.msg);
        setSnackbarSeverity("error");
        setShowSnackbar(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // function handleS3Download(key: string) {
  //   s3Download({ Key: key })
  //     .then((result) => {
  //       downloadFile(result.data.response, key);
  //       setSnackbarMessage("File downloaded!");
  //       setSnackbarSeverity("success");
  //       setShowSnackbar(true);
  //     })
  //     .catch((err) => {
  //       setSnackbarMessage(err);
  //       setSnackbarSeverity("error");
  //       setShowSnackbar(true);
  //     });
  // }

  return (
    <>
      <IconButton sx={{ color: "#fff" }} onClick={() => setEditDetails(true)}>
        <EditIcon />
      </IconButton>
      <IconButton sx={{ color: "#fff" }} onClick={() => setShowDetails(true)}>
        <ViewIcon />
      </IconButton>
      <DeviceViewModal
        open={editDetails}
        handleClose={() => setEditDetails(false)}
        modalType={`Edit ${serialNumber}`}
        props={
          <>
            <CrudTextFields
              setSerialNumber={setSerialNumber}
              serialNumber={serialNumber}
              setCustomerName={setCustomer}
              customerName={customer}
              setDeviceDescription={setDeviceDescription}
              deviceDescription={deviceDescription}
              setVpnIpAddress={setVpnIp}
              inputVpnIpLoading={inputVpnIpLoading}
              setInputVpnIpLoading={setInputVpnIpLoading}
              vpnIpAddress={vpnIp}
              setVpnKey={setVpnKey}
              vpnKey={vpnKey}
              setCert={setCert}
              method="update"
              cert={cert}
            />

            <Box display="flex" justifyContent="space-between" marginTop={3}>
              <Button
                variant="contained"
                color="error"
                onClick={() => setEditDetails(false)}
                sx={{ color: "white", fontWeight: "bold" }}
              >
                Cancel
              </Button>
              {loading ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress color="success" />
                </Box>
              ) : null}
              <Button
                variant="contained"
                color="success"
                sx={{ color: "white", fontWeight: "bold" }}
                onClick={handleUpdate}
              >
                Update Device
              </Button>
            </Box>
            <NotifsSnackBar
              styleProps={{
                position: "absolute",
                bottom: "-20vh",
                // left: "24vw",
                left: snackbarSeverity === "error" ? "24vw" : "30vw",
              }}
              open={showSnackbar}
              setOpen={setShowSnackbar}
              severity={snackbarSeverity}
              message={snackbarMessage}
            />
          </>
        }
      />

      {/* Show Details Modal */}
      <DeviceViewModal
        open={showDetails}
        handleClose={() => setShowDetails(false)}
        modalType={`Device Details ${serialNumber}`}
        props={
          <>
            <Typography sx={{ mt: 2, color: "#fff" }}>Customer</Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: row.row.customer ? "#19f500" : "red" }}
            >
              {row.row.customer ? row.row.customer : "No Customer Configured"}
            </Typography>
            <Typography sx={{ mt: 2, color: "#fff" }}>
              Type of Device
            </Typography>
            <Typography sx={{ color: row.row.product ? "#19f500" : "red" }}>
              {row.row.product ? row.row.product : "No Product Configured"}
            </Typography>
            <Typography sx={{ mt: 2, color: "#fff" }}>Part Number</Typography>
            <Typography sx={{ color: row.row.partNumber ? "#19f500" : "red" }}>
              {row.row.partNumber ? row.row.partNumber : "Unknown"}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, color: "#fff" }}
            >
              Description
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: row.row.description ? "#19f500" : "red" }}
            >
              {row.row.description
                ? row.row.description.replace(/@/g, " ")
                : "No Description Available"}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, color: "#fff" }}
            >
              VPN IP
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: row.row.vpnIp ? "#19f500" : "red" }}
            >
              {row.row.vpnIp ? row.row.vpnIp : "No Ip Address Available"}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, color: "#fff" }}
            >
              VPN Key
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ color: row.row.vpnKey ? "#19f500" : "red" }}
            >
              {row.row.vpnKey ? row.row.vpnKey : "No VPN Key Available"}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              marginTop={2}
            >
              <Typography sx={{ color: "#fff" }} variant="subtitle1">
                Download Certificate
              </Typography>
              <IconButton onClick={() => getCertificate(row.row.serialNumber)}>
                <DownloadIcon sx={{ color: "#19f500" }} />
              </IconButton>
            </Box>
            {/* <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              marginTop={2}
            >
              <Typography sx={{ color: "#fff" }} variant="subtitle1">
                Download Private Key
              </Typography>
              <IconButton
                onClick={() =>
                  handleS3Download(
                    `${row.row.customer}_${row.row.serialNumber}.priv.key`
                  )
                }
              >
                <DownloadIcon sx={{ color: "#19f500" }} />
              </IconButton>
            </Box> */}
            <IconButton
              sx={{
                position: "absolute",
                right: 0,
                top: "10px",
              }}
              onClick={() => setShowDetails(false)}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </>
  );
}
