/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactElement } from "react";
import {
  Box,
  Divider,
  Menu,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  StackProps,
  Typography,
  MenuItemProps,
} from "@mui/material";
import {
  ExitToApp as LogoutIcon,
  Lock as PasswordIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../public/logo.svg";
import BasePage from "./components/BasePage";
import UserButton from "./components/UserButton";
import { useAuthContext } from "./components/AuthContext";

export interface MenuButtonProps extends MenuItemProps {
  icon: ReactElement;
  text: string;
}

export function MenuButton({ icon, text, ...props }: MenuButtonProps) {
  return (
    <MenuItem {...props}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </MenuItem>
  );
}

/*******************************************************************************
 * The main page component for this application
 */
interface PageProps extends StackProps {
  hideUserButton?: boolean;
}

export default function Page({
  hideUserButton,
  children,
  ...props
}: PageProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  return (
    <BasePage
      titleLeft={
        <img
          alt="Westell"
          src={logo}
          height="182px"
          style={{
            paddingLeft: "8px",
            cursor: "pointer",
          }}
        />
      }
      title={
        <Typography sx={{ color: "#dddddd" }} variant="h1" fontWeight={"300"}>
          Support Home
        </Typography>
      }
      {...(!hideUserButton && {
        titleRight: (
          <Box paddingRight="4px">
            <UserButton
              menu={
                <Menu open={false}>
                  <MenuButton
                    icon={<PasswordIcon />}
                    disabled={location.pathname === "/auth"}
                    text="Change Password"
                    onClick={() => {
                      navigate("/auth?method=changepass");
                    }}
                  />
                  <Divider />
                  <MenuButton
                    icon={<LogoutIcon />}
                    text="Logout"
                    onClick={logout}
                  />
                </Menu>
              }
            />
          </Box>
        ),
      })}
      headerSx={{
        alignItems: "center",
      }}
    >
      <Stack flex="1" padding="8px" overflow="hidden" {...props}>
        {children}
      </Stack>
    </BasePage>
  );
}
