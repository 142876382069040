import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import React, { ReactElement } from "react";
import CoreHome from "./home/CoreHome";
import { useAuthContext } from "./components/AuthContext";
import { SessionProvider } from "./SessionContext";
import AuthPage from "./login/AuthPage";

function RequireAuth({ children }: { children: ReactElement }) {
  const { user } = useAuthContext();
  return user ? children : <Navigate to="/auth" />;
}

function MyRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route
        path="/home"
        element={
          <RequireAuth>
            <CoreHome />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default function Content() {
  return (
    <SessionProvider>
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
    </SessionProvider>
  );
}
