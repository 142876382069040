import { GetApp } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";
import { downloadFile } from "../lib/downloadFile";

export function DownloadButtons({
  getPvtkey,
  getCertPem,
  serialNumber,
}: {
  getPvtkey: string;
  getCertPem: string;
  serialNumber: string;
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
      <IconButton
        size="small"
        color="success"
        sx={{
          width: "30%",
          color: "white",
          backgroundColor: "green",
          borderRadius: "3px",
        }}
        onClick={() => {
          downloadFile(getPvtkey, `${serialNumber}.priv.key`);
        }}
      >
        <GetApp /> Keys
      </IconButton>
      <IconButton
        color="success"
        size="small"
        sx={{
          width: "30%",
          color: "white",
          backgroundColor: "green",
          borderRadius: "3px",
        }}
        onClick={() => {
          downloadFile(getCertPem, `${serialNumber}.pem.crt`);
        }}
      >
        <GetApp /> Cert Pem
      </IconButton>
    </Box>
  );
}
