/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import * as React from "react";
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Paper, IconButton, Typography, Tooltip } from "@mui/material";
import { Row, VpnData, getAllDevices } from "../lib/getAllDevices";
import { User, useAuthContext } from "../components/AuthContext";
import { NotifsSnackBar } from "../components/NotifsSnackBar";
import { ConfirmDeleteDialog, SelectedRows } from "../components/ConfirmDelete";
import { AddDeviceModal } from "../components/AddDeviceModal";
import { ConnectionIcon } from "../components/ConnectionIcon";
import { DeviceModals } from "../components/ViewUpdateModals";

/*******************************************************************************
 *  Search Toolbar component
 */

function QuickSearchToolbar() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          margin: "auto",
          position: "absolute",
          top: "-70px",
        }}
      >
        <GridToolbarQuickFilter
          style={{ width: "100%" }}
          quickFilterParser={(searchInput: string) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />
        <Tooltip title={"Add New Device"}>
          <IconButton
            color="primary"
            sx={{ p: "10px", color: "orange" }}
            onClick={() => setOpen(!open)}
            aria-label="directions"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Paper>
      <AddDeviceModal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Box>
  );
}

function isAdmin(user: User): boolean {
  console.log("user email");
  console.log(user.email);
  return (
    user.email === "dabraham@westell.com" ||
    user.email === "seakin@westell.com" ||
    user.email === "rscott@westell.com"
  );
}

const VISIBLE_FIELDS = [
  "id",
  "customer",
  "serialNumber",
  "productCode",
  "firmware",
  "ip",
  "vpn",
  "mqtt",
  "attributes",
];

const COLUMNS: GridColDef[] = VISIBLE_FIELDS.map((field) => {
  const col: GridColDef = {
    field: field,
    headerName: field.charAt(0).toUpperCase() + field.slice(1),
    width: 150,
    headerAlign: "right",
    align: "right",
  };
  if (field === "mqtt") {
    col.width = 100;
    col.renderCell = ConnectionIcon;
  }
  if (field === "customer") {
    col.width = 100;
  }
  if (field === "vpn") {
    col.width = 100;
    col.renderCell = ConnectionIcon;
  }
  if (field === "firmware") {
    col.width = 100;
  }
  if (field === "attributes") {
    col.width = 110;
    col.renderCell = (row): React.ReactNode => {
      return <DeviceModals row={row} />;
    };
  }
  if (field === "productCode") {
    col.headerName = "Product Code";
  }
  if (field === "serialNumber") {
    col.headerName = "Serial Number";
    col.renderCell = (row): React.ReactNode => {
      return (
        <Tooltip
          title={
            row.value === "N/A"
              ? " VPN IP and KEY are not configured to a device"
              : null
          }
        >
          <Typography
            sx={{
              color: row.value === "N/A" ? "#e30000" : "#19f500",
            }}
          >
            {row.value}
          </Typography>
        </Tooltip>
      );
    };
  }
  if (field === "ip") {
    col.headerName = "VPN IP";
  }

  return col;
});

/*******************************************************************************
 * setRowSelection - helper function to select row for deletion.
 * Checks if row is already selected and if so, deselects it.
 * @param row
 * @param selectedRows
 * @param setSelectedRows
 * @returns void
 */

function setRowSelection({
  row,
  selectedRows,
  setSelectedRows,
}: {
  row: any;
  selectedRows: SelectedRows[];
  setSelectedRows: React.Dispatch<React.SetStateAction<SelectedRows[]>>;
}) {
  if (
    selectedRows.some(
      (device: { serialNumber: string }) =>
        device.serialNumber === row.row.serialNumber
    )
  ) {
    return setSelectedRows(
      selectedRows.filter(
        (device: { serialNumber: string }) =>
          device.serialNumber !== row.row.serialNumber
      )
    );
  }
  setSelectedRows([
    {
      serialNumber: row.row.serialNumber,
      vpnIp: row.row.vpnIp ? row.row.vpnIp : "",
      vpnKey: row.row.vpnKey ? row.row.vpnKey : "",
      customerName: row.row.customer ? row.row.customer : "",
    },
  ]);
}
/*******************************************************************************
 * TableView component  - main component
 */
export default function TableView() {
  const [dataGridRows, setDataGridRows] = useState<Row[] | any>([]);
  const [vpnData, setVpnData] = useState<VpnData[]>([]);
  const auth = useAuthContext();
  const isAdminUser = isAdmin(auth.user!);
  console.log("isAdmin: " + isAdminUser);
  /*******************************************************************************
   * Delete Device Modal hooks
   */
  const [selectedRows, setSelectedRows] = useState<SelectedRows[]>([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [loadDelete, setLoadDelete] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  /*******************************************************************************
   * Api call to get all devices
   */
  useEffect(() => {
    getAllDevices({ setDataGridRows, vpnData });
  }, []);

  return (
    <>
      <Box
        sx={{
          height: "65vh",
          width: "80%",
          minWidth: "500px",
          maxWidth: "1500px",
          margin: "auto",
          marginTop: 20,
          backgroundColor: "rgba(255, 255, 255, .15)",
          backdropFilter: "blur(10px)",
          borderRadius: "3px",
        }}
      >
        {openDelete ? (
          <ConfirmDeleteDialog
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            selectedRows={selectedRows}
            setLoadDelete={setLoadDelete}
            setShow={setShow}
            setSnackbarSeverity={setSnackbarSeverity}
            setSnackbarMessage={setSnackbarMessage}
            loadDelete={loadDelete}
          />
        ) : null}
        <DataGrid
          {...dataGridRows}
          sx={{ color: "white", fontSize: 17 }}
          rows={dataGridRows}
          columns={COLUMNS}
          pageSize={10}
          rowHeight={35}
          initialState={{
            ...dataGridRows.initialState,
            columns: {
              ...dataGridRows.initialState?.columns,
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
          onRowClick={(row) => {
            setRowSelection({
              row,
              selectedRows,
              setSelectedRows,
            });
          }}
          rowsPerPageOptions={10}
          components={{
            Toolbar: QuickSearchToolbar,
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>

      <NotifsSnackBar
        setOpen={setShow}
        styleProps={{
          bottom: "15px",
          right: "15px",
          position: "fixed",
          width: "auto",
        }}
        severity={snackbarSeverity}
        open={show}
        message={snackbarMessage}
      />
      {selectedRows.length > 0 && isAdminUser && (
        <IconButton
          sx={{
            position: "relative",
            left: "50%",
          }}
          color="error"
          onClick={() => {
            setOpenDelete(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
}
