/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import { wireguardCUDPeer } from "./wireguardApi";
import { updateThing } from "./iotcrudapi";

interface CrudDeviceHandlerProps {
  thingName: string;
  customer: string;
  deviceDescription: string;
  vpnIp: string;
  vpnKey: string;
  attachCert: boolean;
  apiMethod: (params: any) => Promise<any>;
}

interface CrudResponse {
  message?: string;
  cert?: {
    id: string;
    key: string;
    pem: string;
  };
}

/*******************************************************************************
 *
 */
export async function crudDeviceHandler({
  thingName,
  customer,
  deviceDescription,
  vpnIp,
  vpnKey,
  attachCert,
  apiMethod,
}: CrudDeviceHandlerProps): Promise<CrudResponse> {
  // Check if the input values are valid
  // If they are not valid we show an alert and return
  return new Promise((resolve, reject) => {
    if (thingName.length !== 8 || isNaN(Number(thingName))) {
      return reject("Serial number must be 8 digits");
    }
    if (customer) {
      if (
        customer.includes(" ") ||
        customer.includes("'") ||
        customer.includes("`")
      ) {
        return reject(
          "Customer name cannot include spaces or special characters"
        );
      }
      //customer = encodeURI(customer);
      //console.log("encoded name for customer: " + customer);
    }
    if (deviceDescription) {
      //deviceDescription = encodeURI(deviceDescription);
      //console.log("encoded name for device description: " + deviceDescription);
    }
    if (vpnKey) {
      if (vpnKey.length !== 44 || vpnKey.includes(" ")) {
        return reject(
          "VPN Key must be 44 characters and cannot include spaces"
        );
      }
    }
    console.log(
      "params" +
        thingName +
        customer +
        deviceDescription +
        vpnIp +
        vpnKey +
        attachCert
    );

    if (!vpnIp && !vpnKey) {
      // confirm user doesnt want to create a vpn key and ip
      if (
        !window.confirm(
          apiMethod === updateThing
            ? "Ip and Key are unchanged, was this intentional?"
            : "Are you sure you want to add a device without a VPN key and IP?"
        )
      ) {
        return reject("User cancelled");
      }

      apiMethod({
        thingName,
        customer,
        deviceDescription,
        vpnIp,
        vpnKey,
        attachCert,
      })
        .then((response) => {
          console.log(response);
          // If the createDevice / updateDevice API response is successful we show the download for certs
          if (!response.success) {
            console.log(response);
            return reject({ message: response.errorMessage });
          }
          console.log("Device  success");
          resolve({
            ...(response.data.cert && {
              cert: response.data.cert,
            }),
          });
        })

        // If the createDevice / updateDevice API response is not successful we remove the key and ip from wireguard
        .catch((err) => {
          console.log(err);
          console.log(
            "Error in createDevice / updateDevice API call, removing key and ip from wireguard"
          );
        });
    } else {
      wireguardCUDPeer({
        vpnIp,
        vpnKey,
        method: "addpeer",
      })
        .then((res) => {
          /*******************************************************************************
           * If the wireguard API call is successful we call the createDevice / updateDevice API
           */
          console.log(res);
          console.log(res.data);
          if (res.data.success) {
            console.log("Wireguard  success");
            console.log("calling apiMethod: " + apiMethod);
            apiMethod({
              thingName,
              customer,
              deviceDescription,
              vpnIp,
              vpnKey,
              attachCert,
            })
              .then((response) => {
                if (!response.success) {
                  console.log(response);
                  // If the createDevice / updateDevice API response is not successful we remove the key and ip from wireguard
                  wireguardCUDPeer({
                    vpnIp,
                    vpnKey,
                    method: "removepeer",
                  })
                    .then((res) => {
                      if (!res.data.success) {
                        console.log(
                          "Error in wireguard removepeer API response: " +
                            res.data.msg
                        );
                        return reject({ message: res.data.msg });
                      }
                      return resolve({});
                    })
                    .catch((err) => {
                      console.log(
                        "Error in wireguard removepeer API response: " + err
                      );
                      reject({ message: err.message });
                    });
                  return reject({ message: response.errorMessage });
                }
                // If the createDevice / updateDevice API response is successful we show the download for certs
                console.log("Device  success");
                resolve({
                  ...(response.data.cert && {
                    cert: response.data.cert,
                  }),
                });
              })

              .catch((err) => {
                console.log(
                  "Error in createDevice / updateDevice API response: " + err
                );
                reject({ message: err.message });
              });
          } else {
            console.log("Error in wireguard addpeer API response: " + res.data);
            reject({ message: res.data.msg });
          }
        })
        .catch((err) => {
          console.log("Error in wireguard addpeer API response: " + err);
        });
    }
  });
}
