/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import { GetApp } from "@mui/icons-material";
import {
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
  FormControl,
  FormControlLabel,
  Switch,
  CircularProgress,
  Box,
} from "@mui/material";

import React from "react";
import { getWireguardStatus } from "../lib/wireguardApi";
import { constants } from "../App";

interface CrudTextFieldsProps {
  setSerialNumber: React.Dispatch<React.SetStateAction<string>>;
  serialNumber: string;
  setCustomerName: React.Dispatch<React.SetStateAction<string>>;
  customerName: string;
  setVpnIpAddress: React.Dispatch<React.SetStateAction<string>>;
  vpnIpAddress: string;
  inputVpnIpLoading: boolean;
  setInputVpnIpLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setVpnKey: React.Dispatch<React.SetStateAction<string>>;
  vpnKey: string;
  setDeviceDescription: React.Dispatch<React.SetStateAction<string>>;
  deviceDescription: string;
  setCert: React.Dispatch<React.SetStateAction<boolean>>;
  cert: boolean;
  method: string;
}

export function CrudTextFields(props: CrudTextFieldsProps) {
  const {
    method,
    serialNumber,
    setSerialNumber,
    customerName,
    setCustomerName,
    vpnIpAddress,
    setVpnIpAddress,
    inputVpnIpLoading,
    setInputVpnIpLoading,
    setCert,
    vpnKey,
    setVpnKey,
    setDeviceDescription,
    deviceDescription,
    cert,
  } = props;

  function getNextIp() {
    setInputVpnIpLoading(true);
    getWireguardStatus({
      method: "nextavailable?start=" + constants.WIREGUARD_SUBNET,
    })
      .then((res: { success: boolean; ip: string }) => {
        console.log(res);
        if (res.success) {
          setVpnIpAddress(res.ip);
          setInputVpnIpLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  const ArrayOfTextFields = [
    {
      title: "Serial Number",
      id: "serialNumber",
      placeholder: method === "create" ? "ex: 1234567890" : "",
      value: serialNumber,
      variant: "outlined",
      required: true,
      size: "small",
      onChange: (e: any) => setSerialNumber(e.target.value),
    },
    {
      title: "Device Owner",
      id: "customerName",
      required: false,
      placeholder: method === "create" ? "ex: Test Customer" : "",
      value: customerName,
      variant: "outlined",
      size: "small",
      onChange: (e: any) => setCustomerName(e.target.value),
    },
    {
      title: "VPN IP",
      id: "vpnIpAddress",
      value: vpnIpAddress,
      variant: "outlined",
      required: vpnKey ? true : false,
      size: "small",
      onChange: (e: any) => setVpnIpAddress(e.target.value),
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="Get Next Available IP">
              <IconButton onClick={getNextIp}>
                {inputVpnIpLoading ? (
                  <CircularProgress color="success" />
                ) : (
                  <GetApp />
                )}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      },
    },
    {
      title: "VPN KEY",
      id: "vpnKey",
      required: vpnIpAddress ? true : false,
      placeholder: method === "create" ? "ex: 1234567890" : "",
      value: vpnKey,
      variant: "outlined",
      size: "small",
      onChange: (e: any) => setVpnKey(e.target.value),
    },
    {
      title: "Device Description (optional)",
      id: "deviceDescription",
      required: false,
      placeholder: method === "create" ? "ex: Test Device" : "",
      value:
        method === "create"
          ? deviceDescription
          : deviceDescription
          ? deviceDescription.replace(/@/g, " ")
          : "",
      variant: "outlined",
      size: "small",
      onChange: (e: any) => setDeviceDescription(e.target.value),
    },
  ];

  return (
    <>
      {ArrayOfTextFields.map((textField, index) => {
        return (
          <Box key={index}>
            <Typography sx={{ mt: 2 }}>{textField.title}</Typography>
            <TextField
              sx={{ width: "-webkit-fill-available" }}
              id={textField.id}
              placeholder={textField.placeholder}
              value={textField.value}
              variant="outlined"
              required={textField.required}
              size="small"
              onChange={textField.onChange}
              InputProps={textField.InputProps}
            />
          </Box>
        );
      })}
      <FormControl>
        <FormControlLabel
          value="cert"
          onClick={() => setCert(!cert)}
          control={
            method === "create" ? (
              <Switch color="success" defaultChecked />
            ) : (
              <Switch color="success" />
            )
          }
          label="Create and Attach Certificate to Device"
          labelPlacement="start"
        />
      </FormControl>
    </>
  );
}
