import { listDevices } from "./iotcrudapi";
import { getWireguardStatus } from "./wireguardApi";

export interface VpnData {
  ip: string;
  key: string;
  connected: boolean;
}
export interface Row {
  vpnKey: string;
  id: number;
  serialNumber?: string;
  product: string | null;
  version: number | null;
  customerName: string | null;
  productCode: string | null;
  partNumber: string | null;
  attributes: [object] | null;
  ip: string | null;
  vpnIp: string | null;
  customer: string | null;
  description: string | null;
  firmware: string | null;
  mqtt: boolean | null;
  vpn: boolean | null;
}

export function getAllDevices({
  vpnData,
  setDataGridRows,
}: {
  vpnData: VpnData[];
  setDataGridRows: React.Dispatch<Row[]>;
}) {
  /*******************************************************************************
   * isConnectedToWireguard takes an ip and key from the devices attributes
   * and returns true if the pair exists in the vpnData array and is connected true
   */

  function isConnectedToWireguard(ip: string, key: string): boolean {
    // find the vpn ip and key pair in the vpnData array
    const vpn = vpnData.find((vpn) => {
      return vpn.ip === ip && vpn.key === key;
    });
    // if the vpn exists and is connected return true
    if (vpn) {
      return vpn.connected;
    }
    return false;
  }

  /*******************************************************************************
   * Query the status of the wireguard vpn connections
   */

  getWireguardStatus({
    method: "status",
  })
    .then((vpnStatus: any) => {
      // change to vpnStatus.testData.data for testing
      // console.log("vpnStatus", vpnStatus);
      vpnStatus.data.map((vpn: VpnData) => {
        vpnData.push(vpn);
      });
    })
    .catch((err: any) => {
      console.log("vpnStatus err", err);
    })
    .then(() => {
      /*******************************************************************************
       * Query the list of devices from AWS IoT
       */

      listDevices()
        .then((devices: any) => {
          const rows: Row[] = devices.map(
            (
              device: {
                thingName: string;
                thingTypeName: string | undefined;
                partNumber: string | number | undefined;
                productCode: string | number | undefined;
                version: string | number | undefined;
                connectivity: any;
                attributes: {
                  customerName: string;
                  customer: string;
                  description: string;
                  vpnIp: string;
                  vpnKey: string;
                  productCode: string;
                  partNumber: string;
                };
              },
              index: number
            ) => {
              return {
                id: index,
                customer:
                  device?.attributes?.customerName ||
                  device?.attributes?.customer,
                serialNumber: device.thingName,
                product: device.thingTypeName,
                description: device?.attributes?.description,
                firmware: device.version,
                productCode: device.attributes?.productCode,
                partNumber: device.attributes?.partNumber,
                mqtt: device.connectivity.connected,
                ip: device.attributes?.vpnIp,
                vpn: isConnectedToWireguard(
                  device?.attributes?.vpnIp,
                  device?.attributes?.vpnKey
                ),
                vpnIp: device?.attributes?.vpnIp,
                vpnKey: device?.attributes?.vpnKey,
              };
            }
          );
          // if theres vpnDtata thaty doesnt match a device, add it to the rows array
          vpnData.forEach((vpn) => {
            const vpnRow = rows.find((row) => {
              return row.vpnIp === vpn.ip && row.vpnKey === vpn.key;
            });
            if (!vpnRow) {
              rows.push({
                id: rows.length,
                customer: "",
                serialNumber: "N/A",
                product: "",
                description: "",
                firmware: "",
                productCode: "",
                partNumber: "",
                mqtt: false,

                ip: vpn.ip,
                vpn: vpn.connected,
                vpnIp: vpn.ip,
                version: null,
                customerName: null,
                attributes: null,
                vpnKey: vpn.key,
              });
            }
          });

          setDataGridRows(rows);
        })
        .catch((err) => console.log(err));
    });
}
