/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import { Modal, Box, Typography } from "@mui/material";
import React from "react";

/*******************************************************************************
 * Container for modals (create, edit device and view device)
 */
const ModalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 1.5,
  boxShadow: 14,
  p: 2,
};

export function DeviceViewModal({
  open,
  handleClose,
  modalType,
  props,
}: {
  open: boolean;
  handleClose: () => void;
  props: any;
  modalType: string;
}) {
  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ModalBoxStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalType}
        </Typography>
        <Box display={"flex"} flexDirection={"column"}>
          {props}
        </Box>
      </Box>
    </Modal>
  );
}
