/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import { downloadFile } from "./downloadFile";
import { getDeviceCertificate } from "./iotcrudapi";

/*******************************************************************************
 * function takes in a serial number and calls downloadFile function with the certificate
 */

export function getCertificate(serialNumber: string) {
  const certificate = getDeviceCertificate(serialNumber)
    .then((data) => {
      return data;
    })
    // then download the certificate with the serial number as the file name
    .then((data) => {
      downloadFile(data, serialNumber + ".pem.crt");
    })
    // if no certificate is found, alert
    .catch((err) => {
      alert("Device Certificate Not Found");
    });

  return certificate;
}
