/*******************************************************************************
 * (C) Copyright 2020-2021, 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useEffect, useState } from "react";
import { TextField, TextFieldProps } from "@mui/material";
// @ts-ignore: There's no type file
import cck from "cck";
import { merge } from "lodash";

const regExNumber = RegExp("^-?[0-9]*$");
const regExFloat = RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");
const regExIpv4 = RegExp(
  "^([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])$"
);
const regExIpv6 = RegExp(
  "^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$"
);
/*
const regExEmail = RegExp(
  "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])"
);
*/

type Errors = { [name: string]: string };

export interface Validation {
  errors: Errors;
  setError: (name: string, error: string | null) => boolean;
  hasErrors: boolean;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

/*******************************************************************************
 * Validation hook function.  To be used with ValidationTextField. This object
 * holds all errors associated with Validation fields.
 * Use:
 *
 * const [errors, setErrors, hasErrors] = useValidation();
 *
 * Then onError={setErrors} and the other values will be updated.
 */
export function useValidation(description?: string): Validation {
  const [errors, setErrors] = useState<Errors>({});
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  function filter(values: Errors, key: string) {
    if (values[key]) {
      delete values[key];
    }
    return values;
  }

  useEffect(() => {
    //console.log("Validation: " + description || "no desc");
    //console.log(errors);
    setHasErrors(Object.keys(errors).length > 0);
  }, [errors]);

  return {
    errors,
    setError: (name: string, error?: string | null) => {
      if (error) {
        if (errors[name] !== error) {
          setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
        }
      } else {
        if (errors[name]) {
          setErrors((prevErrors) => ({ ...filter(prevErrors, name) }));
        }
      }
      return Object.keys(errors).length > 0;
    },
    hasErrors,
    visible,
    setVisible,
  };
}

// override name to force required
export type ValidationTextFieldProps = {
  name: string;
  value: string;
  typeName?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  ipv4?: boolean;
  ipv6?: boolean;
  number?: boolean;
  float?: boolean;
  emailAddress?: boolean;
  minValue?: number;
  maxValue?: number;
  match?: string;
  unique?: string;
  disallowChars?: string;
  lockable?: boolean;
  trimStart?: boolean;
  showError?: boolean;
  validation?: Validation;
  onValidate?: (value: string) => string;
  onValidation?: (name: string, value: string | null) => any;
  onChangeValue?: (name: string, value: string) => void;
  onEnter?: () => void;
} & TextFieldProps;

export function ValidationTextField({
  name,
  value,
  typeName,
  required,
  minLength,
  maxLength,
  ipv4,
  ipv6,
  number,
  float,
  emailAddress,
  minValue,
  maxValue,
  match,
  unique,
  disallowChars,
  trimStart,
  lockable,
  showError,
  inputProps,
  validation,
  onValidate,
  onValidation,
  onChangeValue,
  onEnter,
  ...props
}: ValidationTextFieldProps) {
  const [errorVal, setErrorVal] = useState<string | null>(null);

  showError = validation ? validation.visible : showError;

  function setError(error: string | null) {
    setErrorVal(error);
    validation && validation.setError(name, error);
    onValidation && onValidation(name, error);
  }

  function validate(value: string) {
    let error: string | null = null;
    if (match && value !== match) {
      error = (typeName ? typeName : "Value") + " does not match";
    } else if (
      required &&
      (value === undefined ||
        value === null ||
        value.toString().trim().length === 0)
    ) {
      error = "Required";
    } else if (value) {
      if (minLength && (!value || value.length < minLength)) {
        error =
          (typeName ? typeName : "Value") +
          " must be " +
          minLength +
          " characters";
      } else if (unique && value === unique) {
        error = (typeName ? typeName : "Value") + " must be unique";
      } else if (ipv4 && !regExIpv4.test(value)) {
        error = "Valid IPv4 address required";
      } else if (ipv6 && !regExIpv6.test(value)) {
        error = "Valid IPv6 address required";
      } else if (number && !regExNumber.test(value)) {
        error = "Number required";
      } else if (float && !regExFloat.test(value)) {
        error = "Decimal number required";
      } else if (emailAddress && !cck.check(value, "email")) {
        error = "Valid email address required";
      } else if (
        number &&
        minValue !== undefined &&
        parseInt(value) < minValue
      ) {
        error = "Min value " + minValue;
      } else if (
        number &&
        maxValue !== undefined &&
        parseInt(value) > maxValue
      ) {
        error = "Max value " + maxValue;
      } else if (lockable && value.startsWith("*")) {
        error = "Value must not start with *";
      } else if (onValidate) {
        error = onValidate(value);
      }
    }
    setError(error);
  }

  function handleChange(evt: any) {
    const newValue =
      number || float ? evt.target.value.trim() : evt.target.value;
    evt.target.value = newValue;
    props.onChange
      ? props.onChange(evt)
      : onChangeValue && onChangeValue(name, newValue);
  }

  function handleKeyPress(evt: any) {
    if (disallowChars && disallowChars.includes(evt.key)) {
      return evt.preventDefault();
    } else if (
      trimStart &&
      evt.key === " " &&
      evt.target.selectionStart === 0
    ) {
      return evt.preventDefault();
    }
    if (evt.key === "Enter") {
      onEnter && onEnter();
    }
    props.onKeyPress && props.onKeyPress(evt);
  }

  useEffect(() => {
    validate(value);
  }, [required, ipv6, minLength, maxLength, match, unique, value, validate]);

  return (
    <TextField
      name={name}
      value={value || ""}
      required={required}
      error={showError && Boolean(errorVal)}
      helperText={showError && errorVal ? errorVal : props.helperText}
      inputProps={merge(
        {
          autoCapitalize: props.autoCapitalize,
          maxLength: maxLength,
          onKeyPress: handleKeyPress,
        },
        inputProps
      )}
      {...props}
      onChange={handleChange}
    />
  );
}
