/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */

import API from "./api";

/*******************************************************************************
 * wireguardStatus takes a vpnIp, vpnKey, and method and gets the status of the wireguard vpn
 * @param vpnIp
 * @param vpnKey
 * @param  method
 */
export function getWireguardStatus(params: {
  vpnIp?: string;
  vpnKey?: string;
  method: string;
}): Promise<any> {
  const apiName = "/getWireguardStatus";
  const path = "GET";
  const myInit = {
    body: JSON.stringify(params),
    headers: {},
  };
  console.log("params are ", myInit);
  return API.get(apiName, path, myInit);
}

/*******************************************************************************
 * wireguardAddPeer takes a vpnIp, vpnKey, and method and call the wireguardCUDPeer lambda to create update or delete a peer
 * @param vpnIp
 * @param vpnKey
 * @param  method // addpeer, updatepeer, deletepeer
 */
export function wireguardCUDPeer(params: {
  vpnIp?: string;
  vpnKey?: string;
  method: string;
}): Promise<any> {
  const apiName = "/wireguardCUDPeer";
  const path = "POST";
  const myInit = {
    body: JSON.stringify(params),
    headers: {},
  };
  // console.log("params are ", myInit);
  return API.post(apiName, path, myInit);
}
